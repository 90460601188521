import React, {useCallback, useContext} from "react";
import {IAppComponentProps} from "../resources/component-interface";
import styles from "./Cart.module.scss";
import Totals from "./Totals";
import {AppConfigContext, ECartMode} from "../context/AppConfigProvider";
import {CartContext} from "../context/CartProvider";
import {LocaleContext} from "../context/LocaleProvider";
import Coupon from "./Coupon";
import GrandTotal from "./Totals/GrandTotal";
import Header from "./Header";
import Button from "./Button";
import ClipLoader from "react-spinners/ClipLoader";
import ProductCarousel from "./ProductCarousel/ProductCarousel";
import CartFooter from "./CartFooter";
import CartItemsList from "./CartItemsList";
import Messages from './Messages';
import Login from './Login';
import FreeShippingInformation from "./FreeShippingInformation";
import Scalapay from "./Scalapay";
import RewardPoints from "./RewardPoints";

interface IComponentProps extends IAppComponentProps {}

const Cart: React.FC<IComponentProps> = ({ className = "", style = {} }) => {
    const cart = useContext(CartContext);
    const { t } = useContext(LocaleContext);
    const { baseUrl, mode } = useContext(AppConfigContext);

    const handleProceedCheckout = useCallback(() => {
        window.location.href = baseUrl + "checkout";
    }, [baseUrl]);

    const wrapperClassName =
        styles.wrapper +
        " " +
        className +
        " " +
        (cart.open ? styles.open : styles.close) +
        " " +
        (mode === ECartMode.MINI_CART ? styles.miniCart : "") +
        " " +
        (mode === ECartMode.CHECKOUT_SUMMARY ? styles.checkoutSummary : "") +
        " " +
        (mode === ECartMode.CART_SUMMARY ? styles.cartSummary : "");

    if (!cart.cart || !cart.cart.prices) {
        return (
            <div
                className={wrapperClassName + " " + (cart.open ? styles.open : styles.close)}
                style={style}
            >
                <div className={styles.loading}>
                    <div className={styles.spinner}>
                        <ClipLoader size={100} color={"#e2e2e2"} />
                    </div>
                    <div className={styles.text}>{t("Please wait...")}</div>
                </div>
            </div>
        );
    }

    const shouldShowHeader = mode === ECartMode.MINI_CART;
    const shouldShowCartItems = mode === ECartMode.MINI_CART || mode === ECartMode.CHECKOUT_SUMMARY;
    const shouldShowMessages = mode === ECartMode.CART_SUMMARY || mode === ECartMode.CHECKOUT_SUMMARY;
    const shouldShowProceedCheckout = mode !== ECartMode.CHECKOUT_SUMMARY;
    const shouldShowCrossSellProducts = mode !== ECartMode.CHECKOUT_SUMMARY;
    const shouldShowMinicartFooter = false; //mode === ECartMode.CHECKOUT_SUMMARY;
    const stickyBlockClass = mode === ECartMode.MINI_CART ? styles.stickyBlock : '';

    return (
        <div className={wrapperClassName} style={style}>
            {shouldShowHeader && (
                <Header className={styles.cartHeader} itemsCount={Number(cart.cart?.items?.length)} />
            )}
            {shouldShowMessages && <Messages />}

            {cart.loginOpen && <Login />}

            <div className={styles.cartBody}>
                {shouldShowCartItems && <CartItemsList className={styles.cartItemsList} />}

                <div className={stickyBlockClass}>
                    <div className={styles.totals}>
                        <Totals loading={cart.loading} cart={cart.cart} />
                        <RewardPoints cart={cart.cart} loading={cart.loading} removeRewardPoints={cart.removeRewardPoints} />
                        {(!cart.cart.has_free_shipping_rule) && (
                            <FreeShippingInformation loading={cart.loading} cart={cart.cart} isHeavy={cart.cart.is_heavy ?? false}/>
                        )}
                        <Coupon disabled={ cart.loading } couponCode={ cart.couponCode } onUpdateCoupon={cart.applyCoupon} />
                        <GrandTotal loading={ cart.loading } totals={ cart.cart.prices } />
                        <Scalapay loading={ cart.loading } cart={ cart.cart } />
                    </div>

                    {shouldShowProceedCheckout && <div className={styles.ctaWrapper}>
                        <Button className={styles.proceedCheckout} disabled={cart.loading || !cart.canCheckout } onClick={handleProceedCheckout}>
                        {t("Proceed to checkout")}
                        </Button>
                    </div>}
                </div>

                {shouldShowCrossSellProducts && cart.cart.crosssell_products && cart.cart.crosssell_products.length > 0 && (
                    <div className={styles.suggestions}>
                        <div className={styles.label}>{t("Suggested products")}</div>
                        <ProductCarousel products={cart.cart.crosssell_products || []} />
                    </div>
                )}
            </div>

            {shouldShowMinicartFooter && <CartFooter className={styles.cartFooter} />}
        </div>
    );
};

export default Cart;
